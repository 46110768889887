@import '../../styles/colors.module.scss';

.headerContainer {
  padding: 1em;
  background-color: $pentera-blue;
  color: $white;
  position: sticky;
  top: 0;
  align-items: center;
  width: 100%;
  margin: 0;

  .actionsContainer {
    display: flex;
    justify-content: space-around;

    span {
      font-weight: 500;
      cursor: pointer;
      position: relative;
      padding-bottom: 5px;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 3px;
        background-color: transparent;
        transition: background-color 0.3s ease;
      }
    }

    span:hover::after,
    span.active::after {
      background-color: $pentera-yellow;
    }
  }
}
